import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { breakpoints, spacing } from '../../../../utils/styleguide';

const Wrapper = styled.div<{ reversed: boolean; reversedMobile: boolean }>`
  display: flex;
  flex-direction: ${({ reversedMobile }) => (reversedMobile ? 'column' : 'column-reverse')};
  padding: ${spacing[8]}px ${spacing[4]}px;
  gap: ${spacing[6]}px;

  ${breakpoints.tablet} {
    padding: ${spacing[10]}px;
    flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  }

  ${breakpoints.desktop} {
    padding: ${spacing[10]}px ${spacing[11]}px;
  }
`;

const Col = styled.div`
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`;

const Media = styled.div`
  height: 100%;
`;

type HighlightSectionLayoutProps = {
  media: ReactNode;
  content: ReactNode;
  reversed?: boolean;
  dynamicMediaHeight?: boolean;
  reversedMobile?: boolean;
  fixedMediaHeight?: boolean;
  className?: string;
};

const HighlightSectionLayout = ({
  content,
  media,
  reversed = false,
  reversedMobile = false,
  className,
}: HighlightSectionLayoutProps) => {
  return (
    <section className={className}>
      <Wrapper reversed={reversed} reversedMobile={reversedMobile}>
        <Col>
          <Content>{content}</Content>
        </Col>
        <Col>
          <Media>{media}</Media>
        </Col>
      </Wrapper>
    </section>
  );
};

export default HighlightSectionLayout;
