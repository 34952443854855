import styled from '@emotion/styled';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Video } from '../../../utils/api/videoApi';
import { getStreamingSrc, getThumbnailSrc, VIDEO_ORIENTATIONS } from '../../../utils/helpers/video';
import useStreamingVideo from '../../../utils/hooks/useStreamingVideo';
import AspectRatioBox from '../layout/AspectRatioBox';
import OverlayVideoPlayer from '../OverlayVideoPlayer';
import { PlayButton } from './Play';
import { useInView } from 'react-intersection-observer';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const FullVideo = styled.video`
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

/**
 *
 * @param {object} props
 * @param {string} props.src
 * @param {object} props.thumbnailImageSources
 * @param {boolean} props.withDesktopOverlay
 * @param {number} props.aspectRatio
 */

type VideoPlayerProps = {
  video: Video;
  videoRef: MutableRefObject<HTMLVideoElement | undefined>;
  orientation?: VIDEO_ORIENTATIONS;
  withDesktopOverlay?: boolean;
  aspectRatio?: number;
  displayOrientation?: VIDEO_ORIENTATIONS;
  muted?: boolean;
  autoPlay?: boolean;
  startTime?: number;
  loop?: boolean;
};

export default function VideoPlayer({
  video,
  videoRef,
  orientation,
  withDesktopOverlay,
  aspectRatio,
  displayOrientation,
  muted = true,
  autoPlay = false,
  startTime = 0,
  loop,
}: VideoPlayerProps) {
  const [playType, setPlayType] = useState<string | null>(null);
  const { ref: inViewRef, inView } = useInView();

  const src = getStreamingSrc({ video, orientation });
  useStreamingVideo({ src, ref: videoRef });

  const setRefs = useCallback(
    (node: HTMLVideoElement) => {
      videoRef.current = node;
      inViewRef(node);
    },
    [inViewRef, videoRef],
  );

  const autoPlayVideo = useCallback(
    (inView: boolean) => {
      if (inView && autoPlay) {
        videoRef.current?.play();
      }
      if (!inView) {
        videoRef.current?.pause();
      }
    },
    [autoPlay, videoRef],
  );

  useEffect(() => {
    autoPlayVideo(inView);
  }, [autoPlayVideo, inView]);

  useEffect(() => {
    if (startTime && videoRef?.current?.currentTime !== undefined) {
      videoRef.current.currentTime = startTime;
    }
  }, [videoRef, startTime]);

  return (
    <AspectRatioBox
      aspectRatio={displayOrientation == VIDEO_ORIENTATIONS.PORTRAIT ? 9 / 16 : 16 / 9}
    >
      <Wrapper>
        {playType === 'overlay' && withDesktopOverlay && (
          <OverlayVideoPlayer
            src={src}
            onClose={() => setPlayType(null)}
            aspectRatio={aspectRatio}
            videoRef={setRefs}
            videoId={video.id}
          />
        )}
        {!withDesktopOverlay && (
          <FullVideo
            crossOrigin="anonymous"
            ref={setRefs}
            controls={!!playType}
            playsInline
            muted={muted}
            loop={loop}
          >
            <source src={getThumbnailSrc(src)} />
          </FullVideo>
        )}
        {!playType && !autoPlay && (
          <PlayButton
            onClick={() => {
              setPlayType('overlay');
              videoRef.current?.play();
            }}
          />
        )}
      </Wrapper>
    </AspectRatioBox>
  );
}
